

























import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({}) postStore!: PostStore
  @Prop({ default: false }) shortContent
}
